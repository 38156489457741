import React, { ReactNode, useState, FC, useCallback, useEffect } from "react";
import { Placement, StrictModifiers } from "@popperjs/core";
import _ from "lodash";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

export const WithTooltip: FC<{
  className?: string;
  tooltip: ReactNode;
  modifiers?: ReadonlyArray<StrictModifiers>;
  placement: Placement;
  /** Turns off tooltip if true */
  isDisabled?: boolean;
}> = ({
  className = "",
  children,
  tooltip,
  modifiers = [],
  placement = "bottom",
  isDisabled,
}) => {
  const [isHovered, setHovered] = useState(false);
  const [refElement, setRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "preventOverflow", options: { padding: 10 } },
      ...modifiers,
    ],
  });

  const showTip = useCallback(
    () => (isDisabled ? _.noop : setHovered(true)),
    [setHovered, isDisabled]
  );
  const hideTip = useCallback(
    () => (isDisabled ? _.noop : setHovered(false)),
    [setHovered, isDisabled]
  );

  useEffect(() => {
    if (isDisabled) {
      setHovered(false);
    }
  }, [isDisabled, setHovered]);

  return (
    <>
      <span
        className={`tooltip-element ${className}`}
        ref={setRefElement}
        onMouseEnter={showTip}
        onFocus={showTip}
        onMouseLeave={hideTip}
        onBlur={hideTip}
      >
        {children}
      </span>
      {createPortal(
        <span
          ref={setPopperElement}
          {...attributes.popper}
          style={styles.popper}
          className={`pointer-events-none z-50 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          {tooltip}
        </span>,
        document.body
      )}
    </>
  );
};

export default WithTooltip;
